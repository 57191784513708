// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-legal-tsx": () => import("./../src/layouts/legal.tsx" /* webpackChunkName: "component---src-layouts-legal-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-heavyset-tsx": () => import("./../src/pages/heavyset.tsx" /* webpackChunkName: "component---src-pages-heavyset-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seconds-interval-timer-joe-wicks-body-coach-tsx": () => import("./../src/pages/seconds-interval-timer/joe-wicks-body-coach.tsx" /* webpackChunkName: "component---src-pages-seconds-interval-timer-joe-wicks-body-coach-tsx" */),
  "component---src-pages-seconds-interval-timer-press-kit-tsx": () => import("./../src/pages/seconds-interval-timer/press-kit.tsx" /* webpackChunkName: "component---src-pages-seconds-interval-timer-press-kit-tsx" */),
  "component---src-pages-seconds-interval-timer-tsx": () => import("./../src/pages/seconds-interval-timer.tsx" /* webpackChunkName: "component---src-pages-seconds-interval-timer-tsx" */)
}

